<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Ventas en línea</h5>
        {{ formatDate(getTodayDate()) }}
        <div style="margin-bottom: 10px;">
          <div class="flex justify-content-end">
            <!--            <Dropdown-->
            <!--                v-model="selectedCategory"-->
            <!--                :options="categories"-->
            <!--                optionLabel="name"-->
            <!--                :onChange="getData"-->
            <!--                placeholder="Agrupar por"/>-->

            <button class="p-link layout-topbar-button refresh-button" type="button" :onclick="getData">
              <i class="pi pi-refresh"></i>
            </button>
          </div>
        </div>

        <DataTable :value="onlineSalesData"
                   responsiveLayout="scroll"
                   class="p-datatable-gridlines p-datatable-striped"
                   :loading="loadingTable"
                   :key="componentKey">
          <ColumnGroup type="header">
            <Row>
              <Column :header="id" field="no_registro" headerStyle="width: 15px;"/>
              <Column :header="selectedCategory.name" :sortable="true" field="tproducto" footer="TOTAL:"
                      footerStyle="text-align: right;"/>
              <Column header="Monto"
                      :sortable="true"
                      field="venta"
                      headerStyle="width: 120px;"
                      bodyStyle="text-align: right"
                      footerStyle="text-align: right">
                <template #body="{data}">
                  {{ formatCurrency(data.venta) }}
                </template>
                <template #footer>
                  {{ formatCurrency(total) }}
                </template>
              </Column>
              <Column header="Porcentaje"
                      :sortable="true"
                      field="venta"
                      footer="100%"
                      bodyStyle="text-align: right"
                      footerStyle="text-align: right">
                <template #body="{data}">
                  <div class="mt-2 md:mt-0 flex align-items-end" style="width: 100%;">
                    <div class="surface-300 border-round overflow-hidden" style="height:15px; width: 100%;">
                      <div :class="'bg-'+getProductColour(data.no_registro)+'-500 h-full'"
                           :style="'width: '+getPercent(data)+'%'"></div>
                    </div>
                    <span :class="'text-black-500 ml-3 font-medium percent'">{{ getPercent(data) }}%</span>
                  </div>
                  <!--                  {{data.percentage.toFixed(0)}}%-->
                </template>
              </Column>
            </Row>
          </ColumnGroup>
          <ColumnGroup type="footer">

          </ColumnGroup>
        </DataTable>


      </div>
    </div>

  </div>
</template>

<script>
import SaleService from "@/service/backend/SaleService";
import generalMixin from "@/mixins/generalMixin";


export default {
  components: {},
  data() {
    return {
      loadingTable: true,
      loading2: true,
      onlineSalesData: [],
      total: 0,
      identity: null,
      selectedCategory: {name: 'Producto', code: 'producto'},
      categories: [
        {name: 'Producto', code: 'producto'},
        {name: 'Prestador de Servicio', code: 'servicio'},
      ]
    }
  },
  saleService: null,
  mixins: [generalMixin],
  created() {
    this.saleService = new SaleService();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingTable = true;
      this.onlineSalesData = await this.saleService.getOnlineSales({
        identity: this.identity
      });
      this.total = this.calculateTotal(this.onlineSalesData, 'venta');
      this.loadingTable = false
    },
    formatCurrency(value) {
      return Number(value).toFixed(2).replace('.', ',').toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    getPercent(data) {
      let response = 0;
      if (data.venta > 0) {
        response = ((data.venta / this.total) * 100).toFixed(0);
      }
      return response;
    }
  },


  computed: {}
}
</script>

<style scoped lang="scss">


::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

#block_container {
  text-align: center;
}

#block_container > div {
  display: inline-block;
  vertical-align: middle;
  min-width: 10rem;
}

.refresh-button {
  float: right;
  background-color: #004a49;
  color: white;
  padding: 10px
}
</style>
